import blockchainIconByName from 'assets/svg/blockchains';
import { BlockchainEnum, UserBalance } from 'types/user-balance';
import { TetherLogo } from '../../../../../assets/svg/blockchains/TetherLogo';
import React from 'react';
import { MaticLogo } from '../../../../../assets/svg/blockchains/MaticLogo';

export type TBalanceListItem = {
  key: string;
  label: string;
  logo: JSX.Element;
  amount: number;
  balanceId: string;
};

export const balanceConfig = (data: UserBalance[]): TBalanceListItem[] => {
  const bl: TBalanceListItem[] = [];

  for (const key in BlockchainEnum) {
    console.log(key);
    bl.push({
      key: `user_balance_${key}`,
      label: key === 'tether' ? 'TETHER' : key === 'MATIC.POLY' ? 'MATIC' : key === 'BNB.BSC' ? 'BNB.BEP2' : key,
      logo:
        key === 'tether' ? (
          <TetherLogo width={25} height={25} />
        ) : key === 'MATIC.POLY'? <MaticLogo width={30} height={25} /> : (
          blockchainIconByName(key as BlockchainEnum)
        ),
      amount: 0,
      balanceId: '',
    });

    for (let step = 0; step < data.length; step += 1) {
      if (data[step].blockchain === key) {
        bl[bl.length - 1].amount = data[step].amount;
        bl[bl.length - 1].balanceId = data[step].id;
      }
    }
  }

  return bl;
};
