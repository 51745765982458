import React, { useEffect, useState } from 'react';
import { CustomTable } from 'components/Table';
import { columns } from '../utils/tableConfig';
import { usersApi } from 'store/services';
import { useDebouncedState } from '@mantine/hooks';
import { Form, Input, Select } from 'antd';

import styles from './styles.module.scss';
import classNames from 'classnames';

const sizes = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const UsersHome = () => {
  const [fields] = Form.useForm<{ username: string; email: string; phoneNumber: string, perPageSelect: number }>();
  const [perPage, setPerPage] = useState<number>(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterName, setFilterName] = useDebouncedState<string | null>(null, 500);
  const [emailValue, setEmailValue] = useDebouncedState<string | null>(null, 500);
  const [phoneNumber, setPhoneNumber] = useDebouncedState<string | null>(null, 700);

  const { data, isLoading } = usersApi.useGetAllUsersQuery({
    page: currentPage,
    perPage,
    order: 'ASC',
    filterBy: 'createdAt',
    where: JSON.stringify({
      ...(filterName && { username: filterName }),
      ...(emailValue && { email: emailValue }),
      ...(phoneNumber && { phoneNumber: phoneNumber }),
    }),
  });

  const onChangeUsername = (value: string) => {
    setFilterName(value);
    if (value) {
      setEmailValue(null);
      fields.setFieldsValue({ email: '' });
    }
  };

  const onChangeEmail = (value: string) => {
    setEmailValue(value);
    if (value) {
      setFilterName(null);
      fields.setFieldsValue({ username: '' });
    }
  };

  const onChangePhoneNumber = (value: string) => {
    console.log(value);
    setPhoneNumber(value)
  }

  useEffect(() => {
    const localCurrentPage = sessionStorage.getItem('currentPage');
    const localFilterName = sessionStorage.getItem('filterName');
    const localEmailValue = sessionStorage.getItem('emailValue');
    const localPhoneNumberValue = sessionStorage.getItem('phoneNumber');
    const localPerPage = sessionStorage.getItem('perPage');

    if (localCurrentPage) setCurrentPage(Number(localCurrentPage));
    if (localPerPage) {
      fields.setFieldsValue({ perPageSelect: Number(localPerPage) });
      setPerPage(Number(localPerPage));
    }
    if (localFilterName) {
      fields.setFieldsValue({ username: localFilterName });
      setFilterName(localFilterName);
    }
    if (localEmailValue) {
      fields.setFieldsValue({ email: localEmailValue });
      setEmailValue(localEmailValue);
    }
    if (localPhoneNumberValue) {
      fields.setFieldsValue({ phoneNumber: localPhoneNumberValue });
      setPhoneNumber(localPhoneNumberValue);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', String(currentPage));
    sessionStorage.setItem('perPage', String(perPage));
    if (filterName) sessionStorage.setItem('filterName', filterName);
    if (emailValue) sessionStorage.setItem('emailValue', emailValue);
    if (phoneNumber) sessionStorage.setItem('phoneNumber', phoneNumber);

    return () => {
      sessionStorage.removeItem('currentPage');
      sessionStorage.removeItem('perPage');
      sessionStorage.removeItem('filterName');
      sessionStorage.removeItem('emailValue');
      sessionStorage.removeItem('phoneNumber');
    };
  }, [currentPage, perPage, filterName, emailValue, phoneNumber]);

  return (
    <>
      <Form name="user-filters" form={fields}>
        <div className={styles.searchContainer}>
          <Form.Item
            label="Username"
            name="username"
            className={styles.usernameFilter}
          >
            <Input
              placeholder="Enter username"
              name="username"
              onChange={(event) => onChangeUsername(event.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="phoneNumber"
            name="phoneNumber"
            className={classNames(styles.usernameFilter, styles.email)}
          >
            <Input
              placeholder="Enter phone number"
              name="phoneNumber"
              onChange={(event) => onChangePhoneNumber(event.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            className={classNames(styles.usernameFilter, styles.email)}
          >
            <Input
              placeholder="Enter email"
              name="email"
              onChange={(event) => onChangeEmail(event.target.value)}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="perPageSelect"
          className={styles.paginationContainer}
        >
          <Select
            options={sizes}
            defaultValue={15}
            style={{ width: '70px' }}
            onChange={(value) => setPerPage(value)}
          />
        </Form.Item>
      </Form>
      <CustomTable
        loading={isLoading}
        columns={columns()}
        data={data?.data}
        meta={data?.meta}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        hideSizeChanger
      />
    </>
  );
};
