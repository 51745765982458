/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable comma-dangle */
import { $api } from 'api';
import { ICreateCampaign, ICreateCommand } from 'types/traffic';
import { API_ROUTES, BASE_URL } from 'api/routes';
import { TMeta } from 'types';

export const createCommand = async (payload: ICreateCommand) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}`,
    payload
  );

  return data;
};

export const getLandingHtml = async (url: string) => {
  const { data } = await $api.get(url);

  return data;
};

export const getCommands = async () => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}`);

  return data;
};

export const getLandings = async (params?: TMeta) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}`,
    { params }
  );

  return data;
};

export const getLanding = async (id?: string) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/get-one`,
    { params: { id } }
  );

  return data;
};

export const updateLanding = async (payload: any) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/edit`,
    payload
  );

  return data;
};

export const removeLanding = async (id?: string) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/delete`,
    { params: { id } }
  );

  return data;
};

export const createLanding = async (payload: any) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_LANDINGS}/create`,
    payload
  );

  return data;
};

export const createCampaign = async (payload: ICreateCampaign) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}`,
    payload
  );

  return data;
};

export const getTraffic = async (params?: TMeta) => {
  const { data } = await $api.get(`${BASE_URL}/${API_ROUTES.TRAFFIC}`, {
    params,
  });
  console.log(data);
 data.data.forEach((item: any) => {
   if (item.betSum) {
     item.betSum = String(Number(item.betSum).toFixed(2)).replace(/^0+(?=\d)/, '');
   }
   if (item.loseSum) {
     item.winSum = String(Number(item.winSum).toFixed(2)).replace(/^0+(?=\d)/, '');
   }
   if (item.depositsSum) {
     item.depositsSum = item.depositsSum?.toFixed(2);
   }
   console.log(data)
   if (item.ggr) {
     item.ggr = item.ggr.toFixed(2);
   }
   if (item.loseSum) {
     item.loseSum = String(item.loseSum.toFixed(2)).replace(/^0+(?=\d)/, '');
   }
  });

  return data;
};

export const getCampaign = async (id: string | null) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}`,
    {
      params: { ...(id && { id }) },
    }
  );

  return data;
};

export const getCommand = async (id: string | null) => {
  const { data } = await $api.get(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}/one`,
    {
      params: { ...(id && { id }) },
    }
  );

  return data;
};

export const editCampaign = async (payload: any) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}/edit`,
    payload
  );

  return data;
};

export const editCommand = async (payload: any) => {
  const { data } = await $api.post(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}/edit`,
    payload
  );

  return data;
};

export const deleteCampaign = async (id: string) => {
  const { data } = await $api.delete(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_CAMPAIGN}`,
    {
      params: { id },
    }
  );

  return data;
};

export const deleteCommand = async (id: string) => {
  const { data } = await $api.delete(
    `${BASE_URL}/${API_ROUTES.TRAFFIC_COMMAND}`,
    {
      params: { id },
    }
  );

  return data;
};
