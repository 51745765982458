import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import { TBalanceListItem } from 'components/Pages/Users/Balance/utils/balanceConfig';
import { createUserBalanceByAdmin, updateUserBalanceByAdmin } from 'api/user';
import { useParams } from 'react-router-dom';

interface Props {
  open: boolean;
  refetch: () => void;
  item: TBalanceListItem;
  closeHandler: () => void;
}

export const ChangeUserBalanceModal: React.FC<Props> = ({
  open,
  item,
  refetch,
  closeHandler,
}) => {
  const [fields] = Form.useForm();
  const params = useParams();

  const blockchain = item.key.split('user_balance_')[1];

  const onFinish = async () => {
    if (item.balanceId) {
      await updateUserBalanceByAdmin(item.balanceId, fields.getFieldsValue());
    } else {
      if (params.id)
        await createUserBalanceByAdmin(params.id, {
          blockchain,
          ...fields.getFieldsValue(),
        });
    }

    refetch();
    closeHandler();
  };

  useEffect(() => {
    if (item.balanceId) fields.setFieldValue('amount', item.amount);
  }, [item]);

  return (
    <Modal
      title={'User Balance'}
      open={open}
      onOk={closeHandler}
      onCancel={closeHandler}
      footer={null}
    >
      <h3>
        {item.balanceId
          ? `Balance Id - ${item.balanceId}`
          : `Create balance for ${blockchain} balance?`}
      </h3>
      <Form form={fields} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: 'Please enter amount',
                },
              ]}
            >
              <Input placeholder="Please enter amount" />
            </Form.Item>
          </Col>
        </Row>
        <Button key="submit" htmlType="submit" loading={false}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};
